import { SupportedApplication } from "@shopliftr/common-js/shared";

// eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires
const version = require("../../package.json").version;
export const environment = {
    appVersion: version,
    appName: SupportedApplication.PartnerPortal,
    production: false,
    staging: false,
    apiUrl: "https://dev.internal-api.mygrocerydeals.com",
    rollbar: {
        enabled: false,
        environment: "development",
        accessToken: "545c6946038844aeb7b0335520063529"
    },
    authentication: {
        postLoginPath: "/portal",
        logoUrl: "/assets/images/logo-black-shopliftr.svg",
        heading: "Partner Portal",
        logins: [
            {
                title: "API Partner",
                email: "uat_api_partner@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Inspire Partner",
                email: "uat_inspire_partner@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Multi Partner Login",
                email: "uat_multi_partner@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Partner Admin",
                email: "uat_partner_admin@shopliftr.com",
                password: "Password!2"
            }
        ]
    }
};
