import { Component } from "@angular/core";

@Component({
    selector: "partner-portal",
    templateUrl: "./portal.component.html",
    styleUrls: ["./portal.component.scss"]
})
export class PortalComponent {

    constructor() { }

}
