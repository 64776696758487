import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";

import { ApiKeyService } from "@shopliftr/common-ng";

import { ApiCredentialsComponent } from "./api-credentials.component";


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule
    ],
    providers: [
        ApiKeyService
    ],
    exports: [
        ApiCredentialsComponent
    ],
    declarations: [
        ApiCredentialsComponent
    ]
})
export class ApiCredentialsModule {}
