import {
    NgModule
} from "@angular/core";

import { AppComponent } from "./app.component";

import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { PortalModule } from "./portal/portal.module";
import { MarkdownModule } from "ngx-markdown";


@NgModule({
    imports: [
        CoreModule,
        SharedModule,
        PortalModule,
        AppRoutingModule,
        MarkdownModule.forRoot()
    ],
    declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}
