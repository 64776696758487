import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

import { NavigationComponent } from "./navigation/navigation.component";
import { PortalComponent } from "./portal.component";
import { WelcomeComponent } from "./welcome/welcome.component";

import { ApiCredentialsModule } from "../api-credentials/api-credentials.module";
import { PortalRoutingModule } from "./portal-routing.module";
import { SharedModule } from "../shared/shared.module";
import { MatSelectModule } from "@angular/material/select";


@NgModule({
    imports: [
        ApiCredentialsModule,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        PortalRoutingModule,
        SharedModule,
        MatSelectModule
    ],
    declarations: [
        NavigationComponent,
        PortalComponent,
        WelcomeComponent
    ]
})
export class PortalModule {}
