import {
    Component,
    OnInit
} from "@angular/core";

import { Router } from "@angular/router";

import { User } from "@shopliftr/common-js/shared";

import {
    AuthenticationService,
    SupportService
} from "@shopliftr/common-ng";


interface INavItem {
    title: string;
    path: string;
    current?: boolean;
}


@Component({
    selector: "pp-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.scss"]
})
export class NavigationComponent implements OnInit {

    navItems: Array<INavItem>;

    navExpanded: boolean;


    constructor(
        private readonly _router: Router,
        private readonly _authService: AuthenticationService,
        private readonly _supportService: SupportService
    ) { }


    ngOnInit(): void {

        this.navItems = [
            {
                title: "Welcome",
                path: "/portal/welcome"
            },
            {
                title: "API credentials",
                path: "/portal/credentials"
            }
        ];

        const user: User = this._authService.authenticatedUser.getValue();

        if (user.hasPermission("api-docs-read")) {
            this.navItems.push({
                title: "Deals API",
                path: "/portal/docs/deals-api/v3"
            });
        }

        if (user.hasPermission("inspire-docs-read")) {
            this.navItems.push({
                title: "INSPIRE",
                path: "/portal/docs/inspire"
            });
        }

        this.navItems.push({
            title: "Logout",
            path: "/logout"
        });

        this._router.events.subscribe(() => {

            const currentUrl = this._router.url;

            for (let i = 0; i < this.navItems.length; ++i) {
                this.navItems[i].current = this.navItems[i].path === currentUrl;
            }

            this.navExpanded = false;
        });
    }


    /**
     * Invoke the Support service to create a JIRA support ticket.
     */
    onReportProblem(): void {

        this._supportService.requestSupport();
    }


    toggleExpanded(): void {

        this.navExpanded = !this.navExpanded;
    }
}
