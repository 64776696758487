import {
    Component, OnInit
} from "@angular/core";
import {
    ApiKeyService, ExceptionHandlerService
} from "@shopliftr/common-ng";
import { ApiKey } from "@shopliftr/common-js/admin";
import { Results } from "@shopliftr/common-js/shared";

@Component({
    selector: "pp-api-credentials",
    templateUrl: "./api-credentials.component.html",
    styleUrls: ["./api-credentials.component.scss"]
})
export class ApiCredentialsComponent implements OnInit {

    apiKeys: Array<ApiKey>;

    next: string;

    constructor(private readonly _apiKeyService: ApiKeyService,
        private readonly _exceptionHandlerService: ExceptionHandlerService) { }


    ngOnInit(): void {

        this.apiKeys = [];
        this.updateApiKeys();
    }


    updateApiKeys(): void {

        this._apiKeyService.getApiKeys(null, false, this.next).subscribe({
            next: (results: Results<ApiKey>) => {

                this.apiKeys = this.apiKeys.concat(results.results);

                if (this.apiKeys.length >= results.total) {
                    this.next = null;
                }
                else {
                    this.next = results?.lastIndex?.toString();
                }
            },
            error: (error) => this._exceptionHandlerService.sendException(error)
        });
    }
}
