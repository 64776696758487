import {
    Component, OnInit
} from "@angular/core";
import { AuthenticationService } from "@shopliftr/common-ng";
import { User } from "@shopliftr/common-js/shared";

@Component({
    selector: "pp-welcome",
    templateUrl: "./welcome.component.html",
    styleUrls: ["./welcome.component.scss"]
})
export class WelcomeComponent implements OnInit {
    showDealsApi: boolean;

    showInspire: boolean;

    constructor(private readonly _authService: AuthenticationService) { }


    ngOnInit(): void {

        const user: User = this._authService.authenticatedUser.getValue();

        this.showDealsApi = user.hasPermission("api-docs-read");
        this.showInspire = user.hasPermission("inspire-docs-read");
    }
}
