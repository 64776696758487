<div class="container small">
    <div class="page-header">
        <h1>ShopLiftr Api Credentials</h1>
    </div>

    <section class="endpoint">
        <article data-layout="row wrap">
            <strong data-flex="100" data-flex-gt-xs="33">
                API endpoint
            </strong>
            <span data-flex="100" data-flex-gt-xs="67">
                <code>https://api.shopliftr.com/v3/</code>
            </span>
        </article>
    </section>

    <section class="api-credentials">
        <article *ngFor="let apiKey of apiKeys" class="api-credential">
            <div data-layout="row wrap">
                <strong data-flex="100" data-flex-gt-xs="33">Name</strong>
                <span data-flex="100" data-flex-gt-xs="67">{{ apiKey.name }}</span>
            </div>
            <div data-layout="row wrap">
                <strong data-flex="100" data-flex-gt-xs="33">API Key</strong>
                <span data-flex="100" data-flex-gt-xs="67">
                    <code>{{ apiKey.key }}</code>
                </span>
            </div>
            <div data-layout="row wrap">
                <strong data-flex="100" data-flex-gt-xs="33">Plan</strong>
                <span data-flex="100" data-flex-gt-xs="67">{{ apiKey.usagePlan.name}}</span>
            </div>
            <div *ngIf="apiKey.usagePlan.quota" data-layout="row wrap">
                <strong data-flex="100" data-flex-gt-xs="33">Limit</strong>
                <span data-flex="100" data-flex-gt-xs="67">
                    {{ apiKey.usagePlan.quota.limit }} requests per {{ apiKey.usagePlan.quota.period }}
                </span>
            </div>
        </article>
    </section>

    <div *ngIf="next" class="text-center lazy-load-container" data-layout="row wrap">
        <button mat-raised-button type="button" (click)="updateApiKeys()" data-flex="100" class="load-more">
            Load more...
        </button>
    </div>
</div>
