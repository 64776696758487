<header>
    <nav class="navbar container">
        <div class="navbar-brand">
            <a [routerLink]="['/welcome']">
                <img src="/assets/images/logo-white-shopliftr.svg" />
            </a>

            <span>Partner Portal</span>
        </div>

        <button mat-icon-button type="button" aria-label="Toggle Navigation" class="navbar-toggle" (click)="toggleExpanded()">
            <mat-icon>
                menu
            </mat-icon>
        </button>

        <ul class="nav navbar-nav" [class.expanded]="navExpanded">
            <li *ngFor="let navItem of navItems" class="nav-item">
                <a class="nav-link" [routerLink]="navItem.path" [class.active]="navItem.current">
                    {{navItem.title}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="[]" (click)="onReportProblem()">
                    Report a Problem
                </a>
            </li>
        </ul>
    </nav>
</header>
