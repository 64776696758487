import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    FormsModule, ReactiveFormsModule
} from "@angular/forms";
import { RouterModule } from "@angular/router";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: []
})
export class SharedModule {}
