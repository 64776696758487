import {
    ErrorHandler, NgModule
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    HttpClientModule, HTTP_INTERCEPTORS
} from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";

import {
    AuthenticationModule,
    ExceptionHandlerModule,
    NotificationModule,
    CommonErrorHandler,
    CommonHttpInterceptor,
    InternalApiInterceptor,
    MgdHotkeyModule
} from "@shopliftr/common-ng";

import { environment } from "../../environments/environment";


@NgModule({
    imports: [
        AuthenticationModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        ExceptionHandlerModule,
        HttpClientModule,
        NotificationModule,
        RouterModule,
        MgdHotkeyModule
    ],
    declarations: [],
    providers: [
        {
            provide: "AppConfig",
            useValue: environment
        },
        {
            provide: ErrorHandler,
            useClass: CommonErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CommonHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InternalApiInterceptor,
            multi: true
        }
    ],
    exports: [
        AuthenticationModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        ExceptionHandlerModule,
        HttpClientModule,
        NotificationModule,
        RouterModule
    ]
})
export class CoreModule {}
