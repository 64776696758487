import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { AuthenticationGuard } from "@shopliftr/common-ng";

import { PortalComponent } from "./portal.component";
import { WelcomeComponent } from "./welcome/welcome.component";
import { ApiCredentialsComponent } from "../api-credentials/api-credentials.component";


@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: "portal",
                component: PortalComponent,
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "welcome"
                    },
                    {
                        path: "welcome",
                        component: WelcomeComponent
                    },
                    {
                        path: "docs",
                        loadChildren: () => import("../docs/docs.module").then((m) => m.DocsModule)
                    },
                    {
                        path: "credentials",
                        component: ApiCredentialsComponent
                    }
                ],
                canActivate: [AuthenticationGuard]
            }
        ])
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthenticationGuard
    ]
})
export class PortalRoutingModule {}

